import React, { useCallback } from "react"
import { Redirect } from "react-router-dom"
import styled from "styled-components"
import { pxToRem } from "@cbs-sports/sports-shared-client/build/cjs/utils/style-utils"
import constants, { registeredTrademark } from "../../../../../common/constants"
import { withoutDomain } from "../../../../utils/url-utils"
import { useDeviceType } from "../../../../Base/DeviceType"
import useMainPromoInfo, { ProductInfoType } from "../../../../hooks/useMainPromoInfo"
import GameProductCTA, { GamePromoCTAProps } from "./GamePromoCTA"
import GamePromoItemMobile from "./GamePromoItemMobile"
import SingleGamePromoItem from "./SingleGamePromoItem"
import Analytics from "../../../../utils/analytics"
import { isNCAAWTournamentMatcher } from "../../../../../common/common-utils-helpers"
import MainPromoRoot from "./MainPromoRoot"

const BRACKET_LOCKED_LABEL = "Bracket Locked"
export const NISSAN_PRIZE_LABEL = `${constants.MARCH_MADNESS_SPORT_YEAR + 1} Nissan Rogue®`
export const NISSAN_PRIZE_IMAGE_PATH = "/static/sport-assets/nissan_prize.png"

const ncaabFTAG = {
  1: "", //"?ftag=CBS-16-10ach7a", // first entry we redirect to /challenge
  2: "&ftag=CBS-16-10ach7b",
  3: "&ftag=CBS-16-10ach7c",
}
const ncaawFTAG = {
  1: "", //"?ftag=CBS-16-10ach7d", // first entry we redirect to /challenge
  2: "&ftag=CBS-16-10ach7e",
  3: "&ftag=CBS-16-10ach7f",
}

export const ProductLogo = styled.img<{ width?: number }>`
  width: ${({ width = 70 }) => pxToRem(width)};
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
`

export const PrizeLogo = styled.img<{ width?: number }>`
  width: ${({ width = 192 }) => pxToRem(width)};
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
`

const ComposedLogo = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
  max-width: ${pxToRem(200)};

  img:first-child {
    grid-column: 1 / span 8;
    grid-row: 1;
  }

  img:last-child {
    grid-column: 3 / -1;
    grid-row: 1;
    z-index: 1;
    padding-top: 3rem;
  }
`

const MainPromo: React.VFC = () => {
  const { ncaabInfo, ncaawInfo, prizeUnloked, isAuthenticated, rulesUrl, isInNcaawLobby } = useMainPromoInfo()
  const deviceType = useDeviceType()
  const isMobile = deviceType === "handheld"

  /**
   * When user is not autenticated or user is creating first entry, this function will
   * return a `to` prop poiting to the challenge pool url for the game info provided.
   * CTA component will render just a regular link when `to` prop is present.
   *
   * It will return an `onClick` action otherwise that will start the process of creating a new
   * entry for the game info provided. After successfully creating the entry, user will be redirected
   * to the entry page
   */
  const getCTAProps: (
    info: ProductInfoType,
    fullInfo: { ncaabInfo: ProductInfoType; ncaawInfo: ProductInfoType },
  ) => Partial<GamePromoCTAProps> = React.useCallback(
    (info: ProductInfoType, fullInfo: { ncaabInfo: ProductInfoType; ncaawInfo: ProductInfoType }) => {
      const isWomens = isNCAAWTournamentMatcher.test(info.gameInstanceUid)
      const triggerTracking = () => {
        Analytics.trackAction("bracket lobby", "bracket games play for prizes", `enter bracket - ${isWomens ? "women" : "men"}`, "click", {
          challengeStatus: `${fullInfo.ncaabInfo.numberOfEntries}|${fullInfo.ncaawInfo.numberOfEntries}`,
        })
      }
      if (isAuthenticated && info.numberOfEntries > 0) {
        const onClick: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> = (e) => {
          e.preventDefault()
          e.stopPropagation()
          info.upsertEntry()
        }
        return { onClick, triggerTracking }
      }

      //take the user to the bracket pagne when is anonymous or creating first bracket
      const ftag = info.productAbbrev === "wbpc" ? ncaawFTAG[1] : ncaabFTAG[1]
      return { to: `${info.lobbyUrl}/challenge${ftag}`, triggerTracking }
    },
    [isAuthenticated],
  )

  const triggerImpressionTracking = useCallback(() => {
    Analytics.trackAction("bracket lobby", "bracket games play for prizes", "brackets interaction", "impression", {
      challengeStatus: `${ncaabInfo.numberOfEntries}|${ncaawInfo.numberOfEntries}`,
    })
  }, [ncaabInfo.numberOfEntries, ncaawInfo.numberOfEntries])

  //Redirect to ncaab new entry after created
  if (ncaabInfo.upsertEntryResult?.data?.upsertEntry?.entry?.url) {
    let url = ncaabInfo.upsertEntryResult?.data?.upsertEntry?.entry?.url
    if (ncaabInfo.numberOfEntries) {
      const ftag = ncaabFTAG[ncaabInfo.numberOfEntries]
      if (ftag) {
        url = `${url}${ftag}`
      }
    }
    return <Redirect to={withoutDomain(url)} />
  }

  //Redirect to ncaaw new entry after created
  if (ncaawInfo.upsertEntryResult?.data?.upsertEntry?.entry?.url) {
    let url = ncaawInfo.upsertEntryResult?.data?.upsertEntry?.entry?.url
    if (ncaawInfo.numberOfEntries) {
      const ftag = ncaawFTAG[ncaawInfo.numberOfEntries]
      if (ftag) {
        url = `${url}${ftag}`
      }
    }
    return <Redirect to={withoutDomain(url)} />
  }

  /**
   * Remove component when both games are locked
   */
  if (ncaabInfo.isLocked && ncaawInfo.isLocked) {
    return null
  }

  /**
   * User already have created the max amount of brackets
   */
  if (ncaabInfo.remainingEntries === 0 && ncaawInfo.remainingEntries === 0) {
    return null
  }

  /**
   * User have max amount of entries for NCAAW and NCAAB is locked
   */
  if (ncaawInfo.remainingEntries <= 0 && ncaabInfo.isLocked) {
    return null
  }

  /**
   * User have max amount of entries for NCAAB and NCAAW is locked
   */
  if (ncaabInfo.remainingEntries <= 0 && ncaawInfo.isLocked) {
    return null
  }

  /**
   * When at least one of the games is in pre-tournament state, we proceed to show phase-1.
   *
   * In this phase, we will be using a banner with a single call to action and drive users to create a bracket.
   */
  if (ncaabInfo.isPreTournament || ncaawInfo.isPreTournament) {
    if (isInNcaawLobby) {
      if (ncaawInfo.numberOfEntries > 0) {
        return null
      }
      return (
        <SingleGamePromoItem
          isLoggedIn={isAuthenticated}
          rulesUrl={rulesUrl}
          info={ncaawInfo}
          useAsComponent
          isFromLobby
          copy={`Play Bracket Challenge for a chance to win a trip to the ${
            constants.MARCH_MADNESS_SPORT_YEAR + 2
          } Women's Final Four${registeredTrademark}.`}
          logoNode={<ProductLogo width={147} src="/static/game-assets/cbs-ncaaw-tournament/final_four.svg" alt="NCAAW Final Four logo" />}
          impressionTracking={triggerImpressionTracking}
        />
      )
    } else {
      if (ncaabInfo.numberOfEntries > 0) {
        return null
      }
      return (
        <SingleGamePromoItem
          isLoggedIn={isAuthenticated}
          rulesUrl={rulesUrl}
          info={ncaabInfo}
          useAsComponent
          isFromLobby
          copy={`Play Bracket Challenge for a chance to win a trip to the ${
            constants.MARCH_MADNESS_SPORT_YEAR + 2
          } Men's Final Four${registeredTrademark}.`}
          logoNode={<ProductLogo width={180} src="/static/game-assets/cbs-ncaab-tournament/final_four.svg" alt="NCAAB Final Four logo" />}
          impressionTracking={triggerImpressionTracking}
        />
      )
    }
  }

  /**
   * Handling the case when NCAAB game is already locked but NCAAW is still open
   *
   * We will be using a banner with a single call to action and drive users to create a bracket.
   */
  if (ncaabInfo.isLocked) {
    if (ncaawInfo.numberOfEntries > 0) {
      return null
    }

    if (ncaabInfo.numberOfEntries > 0) {
      return (
        <SingleGamePromoItem
          isLoggedIn={isAuthenticated}
          rulesUrl={rulesUrl}
          info={ncaawInfo}
          useAsComponent
          isFromLobby
          header="There's Still a Chance to Win"
          copy={`Play Women's Bracket Challenge now and get entered to win an All-New ${NISSAN_PRIZE_LABEL}!  Plus, you'll compete to win a trip to the ${
            constants.MARCH_MADNESS_SPORT_YEAR + 2
          } Women's Final Four${registeredTrademark}.`}
          logoNode={
            <ComposedLogo>
              <ProductLogo width={100} src="/static/game-assets/cbs-ncaaw-tournament/final_four.svg" alt="NCAAW Final Four logo" />
              <PrizeLogo width={185} src={NISSAN_PRIZE_IMAGE_PATH} alt="Nissan Prize" />
            </ComposedLogo>
          }
          impressionTracking={triggerImpressionTracking}
        />
      )
    } else {
      return (
        <SingleGamePromoItem
          isLoggedIn={isAuthenticated}
          rulesUrl={rulesUrl}
          info={ncaawInfo}
          header="There's Still a Chance to Win"
          copy={`Enter Women's Bracket Challenge for a chance to win a trip to the ${
            constants.MARCH_MADNESS_SPORT_YEAR + 2
          } Women's Final Four${registeredTrademark}.`}
          logoNode={<ProductLogo width={147} src="/static/game-assets/cbs-ncaaw-tournament/final_four.svg" alt="NCAAW Final Four logo" />}
          impressionTracking={triggerImpressionTracking}
        />
      )
    }
  }

  /**
   * Handling components on mobile view
   *
   * View will depend on current lobby, state of the games and number of entries for each game
   * In NCAAB lobby:
   *  NCAAB locked                              -> hide
   *  NCAAB entries = 0                         -> show NCAAB entry create component
   *  NCAAB entries > 0 AND NCAAW entries = 0   -> show NCAAW entry create component
   *  NCAAB entries > 0 AND NCAAW entries > 0   -> hide
   *
   * Logic is similar in NCAAW lobby, but reversed
   *  NCAAW locked                              -> hide
   *  NCAAW entries = 0                         -> show NCAAW entry create component
   *  NCAAW entries > 0 AND NCAAB entries = 0   -> show NCAAB entry create component
   *  NCAAW entries > 0 AND NCAAW entries > 0   -> hide
   */
  if (isMobile) {
    if (isInNcaawLobby) {
      if (ncaawInfo.isLocked) {
        return null
      } else if (ncaawInfo.numberOfEntries === 0) {
        return (
          <GamePromoItemMobile
            header="Enter a Bracket, Win Big!"
            copy={`Play Women's Bracket Challenge for a chance to win a trip to the ${
              constants.MARCH_MADNESS_SPORT_YEAR + 2
            } Women's Final Four${registeredTrademark}`}
            rulesUrl={rulesUrl}
            ctaNode={<GameProductCTA copy="Enter Bracket" variant="primary" {...getCTAProps(ncaawInfo, { ncaabInfo, ncaawInfo })} />}
            impressionTracking={triggerImpressionTracking}
          />
        )
      } else if (ncaabInfo.numberOfEntries === 0) {
        return (
          <GamePromoItemMobile
            header="Enter a Bracket, Win Big!"
            copy={`Play Men's Bracket Challenge now and get entered to win an All-New ${NISSAN_PRIZE_LABEL}!  Plus, you'll compete to win a trip to the ${
              constants.MARCH_MADNESS_SPORT_YEAR + 2
            } Men's Final Four${registeredTrademark}.`}
            rulesUrl={rulesUrl}
            ctaNode={<GameProductCTA copy="Enter Bracket" variant="primary" {...getCTAProps(ncaabInfo, { ncaabInfo, ncaawInfo })} />}
            impressionTracking={triggerImpressionTracking}
          />
        )
      }
    } else {
      if (ncaabInfo.isLocked) {
        return null
      } else if (ncaabInfo.numberOfEntries === 0) {
        return (
          <GamePromoItemMobile
            header="Enter a Bracket, Win Big!"
            copy={`Play Men's Bracket Challenge for a chance to win a trip to the ${
              constants.MARCH_MADNESS_SPORT_YEAR + 2
            } Men's Final Four${registeredTrademark}`}
            rulesUrl={rulesUrl}
            ctaNode={<GameProductCTA copy="Enter Bracket" variant="primary" {...getCTAProps(ncaabInfo, { ncaabInfo, ncaawInfo })} />}
            impressionTracking={triggerImpressionTracking}
          />
        )
      } else if (ncaawInfo.numberOfEntries === 0) {
        return (
          <GamePromoItemMobile
            header="Enter a Bracket, Win Big!"
            copy={`Play Women's Bracket Challenge now and get entered to win an All-New ${NISSAN_PRIZE_LABEL}!  Plus, you'll compete to win a trip to the ${
              constants.MARCH_MADNESS_SPORT_YEAR + 2
            } Women's Final Four${registeredTrademark}.`}
            rulesUrl={rulesUrl}
            ctaNode={<GameProductCTA copy="Enter Bracket" variant="primary" {...getCTAProps(ncaawInfo, { ncaabInfo, ncaawInfo })} />}
            impressionTracking={triggerImpressionTracking}
          />
        )
      }
    }
    return null
  }

  const ncaabCTANode = ncaabInfo.isLocked ? (
    <GameProductCTA disabled copy={BRACKET_LOCKED_LABEL} data-cy="ncaab-enter-bracket-btn" />
  ) : (
    <GameProductCTA
      {...getCTAProps(ncaabInfo, { ncaabInfo, ncaawInfo })}
      copy="Enter Bracket"
      loading={ncaabInfo.upsertEntryResult?.loading}
      variant="secondary"
      data-cy="ncaab-enter-bracket-btn"
    />
  )

  const ncaawCTANode = ncaawInfo.isLocked ? (
    <GameProductCTA disabled copy={BRACKET_LOCKED_LABEL} variant="primary" data-cy="ncaabw-enter-bracket-btn" />
  ) : (
    <GameProductCTA
      {...getCTAProps(ncaawInfo, { ncaabInfo, ncaawInfo })}
      copy="Enter Bracket"
      loading={ncaawInfo.upsertEntryResult?.loading}
      variant="secondary"
      data-cy="ncaabw-enter-bracket-btn"
    />
  )

  return (
    <MainPromoRoot
      ncaabInfo={ncaabInfo}
      ncaawInfo={ncaawInfo}
      ncaabCTANode={ncaabCTANode}
      ncaawCTANode={ncaawCTANode}
      prizeUnlocked={prizeUnloked}
      rulesUrl={rulesUrl}
      impressionTracking={triggerImpressionTracking}
    />
  )
}

export default React.memo(MainPromo)
