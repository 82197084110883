import React from "react"
import { Redirect } from "react-router"
import { withoutDomain } from "../../../../common/url-utils"
import { TPoolRouteProps } from "../../../../routes.d"
import Status from "../../../Base/Status"
import LoadingView from "../../../components/LoadingView"
import { getAuthGateProps } from "../../../hooks/usePoolData"
import OnboardingView from "../components/OnboardingView"
import MultipleEntriesLobby from "../components/MultipleEntriesLobby"

// const MultiEntryGamesStyled = styled.div``
function Games(props: TPoolRouteProps) {
  const { location, poolData } = props
  const {
    segmentForArea,
    hasOnlyAvailableEntryForSport,
    // managerGI,
    // challengeGI,
    gameInstances,
    gameRequiresAuthGateForPickPage,
    hasCbsAccount,
    gameInstanceUid,
    isCbsAppWebview,
    allCurrentEntries,
    allSegments,
    // currentSportTypes,
    isSingleGameInstanceArea,
    areaHasMultipleEntriesPerUser,
  } = poolData
  const challengePoolPath = (segmentForArea && withoutDomain(segmentForArea.landingUrl)) || ""
  // const dropOnPicksPage = hasOnlyAvailableEntryForSport || (challengePoolPath && gameInstances.length === 1 && !isParlay);
  if (hasOnlyAvailableEntryForSport && challengePoolPath) {
    return (
      <Status code={302}>
        <Redirect to={challengePoolPath} />
      </Status>
    )
  }
  if (!allSegments.length) {
    return <LoadingView />
  }
  if (areaHasMultipleEntriesPerUser) {
    return <MultipleEntriesLobby {...props} />
  }
  const { returnUrl, productAbbrev, masterProductId } = getAuthGateProps(poolData, location)
  return (
    <OnboardingView
      gameInstances={gameInstances}
      allCurrentEntries={allCurrentEntries}
      allSegments={allSegments}
      needsAuthGate={gameRequiresAuthGateForPickPage && !hasCbsAccount}
      returnUrl={returnUrl}
      productAbbrev={productAbbrev}
      masterProductId={masterProductId}
      gameInstanceUid={gameInstanceUid}
      isCbsAppWebview={isCbsAppWebview}
      sportType={(segmentForArea && segmentForArea.sportType) || ""}
      isSingleGameInstanceArea={isSingleGameInstanceArea}
      {...props.match}
    />
  )
}

// force build

export default Games
