import { useContext } from "react"
import { emptyObject, NCAAB_GAME_INSTANCE_UID, NCAAW_GAME_INSTANCE_UID } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { generatePath } from "react-router"
import constants, { registeredTrademark } from "../../common/constants"
import PoolDataContex, { PoolDataContextType } from "../Contexts/PoolDataContext"
import { edgeToNode } from "../utils/misc-utils"
import { isCurrentUserLoggedIn } from "../utils/data-utils"
import useUpsertEntryMutation from "./useUpsertEntryMutation"
import buildMappingFor from "../../common/game-text"
import { ENUM_POST, PoolSettingsTypesEnum } from "../../common/enums"
import { CentralCurrentUsersEntriesQuery_currentUser_entries } from "../../__generated__/CentralCurrentUsersEntriesQuery"

type UpsertEntryMutationReturnType = ReturnType<typeof useUpsertEntryMutation>
type UpsertEntryMutationResultType = UpsertEntryMutationReturnType["upsertEntryMutationResult"]

const NCAAB_HEADER = "Men's Bracket Challenge"
const NCAAW_HEADER = "Women's Bracket Challenge"

// MARCH_MADNESS_SPORT_YEAR = sport year of NCAA(B|W) which is 1 year behind brackets since brackets is post season, and this is a trip for next year, so it's + 2
const year = constants.MARCH_MADNESS_SPORT_YEAR + 2

const NCAAB_COPIES = [
  `You've entered the max brackets for a chance to win a trip to the ${year} Men's Final Four${registeredTrademark}`,
  `One more chance to enter to win a trip to the ${year} Men's Final Four${registeredTrademark}`,
  `Two more chances to enter to win a trip to the ${year} Men's Final Four${registeredTrademark}`,
  `Enter for a chance to win a trip to the ${year} Men's Final Four${registeredTrademark}`,
]

const NCAAW_COPIES = [
  `You've Entered the max brackets for a chance to win a trip to the ${year} Women's Final Four${registeredTrademark}`,
  `One more chance to enter to win a trip to the ${year} Women's Final Four${registeredTrademark}`,
  `Two more chances to enter to win a trip to the ${year} Women's Final Four${registeredTrademark}`,
  `Enter for a chance to win a trip to the ${year} Women's Final Four${registeredTrademark}`,
]

export interface ProductInfoType {
  isLocked: boolean
  isPreTournament: boolean
  numberOfEntries: number
  maxNumberOfEntries: number
  remainingEntries: number
  order: number
  gameInstanceUid: string
  lobbyUrl: string
  header: string
  copy: string
  upsertEntry: () => void
  upsertEntryResult?: UpsertEntryMutationResultType
  seasonType?: string
  seasonYear?: number
  productAbbrev?: string
}

export interface PromoInfoType {
  ncaabInfo: ProductInfoType
  ncaawInfo: ProductInfoType
  prizeUnloked: boolean
  rulesUrl: string
  isInNcaawLobby: boolean
  isAuthenticated: boolean
}

export function getNumberOfChallengeEntries(entries: CentralCurrentUsersEntriesQuery_currentUser_entries | undefined) {
  return entries?.edges.map(edgeToNode).reduce(
    (acc, current) => {
      if (current.pool.gameInstanceUid === NCAAB_GAME_INSTANCE_UID) {
        acc.ncaab++
      } else if (current.pool.gameInstanceUid === NCAAW_GAME_INSTANCE_UID) {
        acc.ncaaw++
      }
      return acc
    },
    { ncaab: 0, ncaaw: 0 },
  )
}

function useMainPromoInfo() {
  const context = useContext<PoolDataContextType>(PoolDataContex)
  const isInNcaawLobby = context?.gameInstanceUid === NCAAW_GAME_INSTANCE_UID
  const isAuthenticated = isCurrentUserLoggedIn(context?.currentUser ?? emptyObject)

  const numberOfEntries = getNumberOfChallengeEntries(context?.currentUser?.entries)
  //getting required information for NCAAB
  const { upsertEntryMutation: upsertNcaabEntryMutation, upsertEntryMutationResult: upsertNcaabEntryMutationResult } = useUpsertEntryMutation()
  const ncaabBracketState = context?.globalBracketState?.mensBracketState
  const ncaabGameInstance = context?.allGameInstances.find((gi) => gi.uid === NCAAB_GAME_INSTANCE_UID)
  const ncaabPoolSettings = ncaabGameInstance?.currentPeriod?.segment?.season?.challengePool?.poolSettings
  const ncaabMaxEntriesPerUser =
    ncaabPoolSettings?.__typename === PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS ? ncaabPoolSettings.maxEntriesPerUser : 0
  const ncaabNumberOfEntries = numberOfEntries?.ncaab ?? 0
  const ncaabRemainingEntries = ncaabMaxEntriesPerUser - ncaabNumberOfEntries
  const ncaabInfo: ProductInfoType = {
    isLocked: ncaabBracketState?.isLocked ?? false,
    isPreTournament: ncaabBracketState?.isPreTournament ?? true,
    numberOfEntries: ncaabNumberOfEntries,
    maxNumberOfEntries: ncaabMaxEntriesPerUser,
    remainingEntries: ncaabRemainingEntries,
    order: isInNcaawLobby ? 2 : 1,
    lobbyUrl: generatePath(constants.BRACKET_LOBBY_ROUTE, {
      sportType: "college-basketball",
      subsection: "ncaa-tournament",
      gameType: "bracket",
    }),
    gameInstanceUid: constants.NCAAB_CHALLENGE_GAME_INSTANCE_UID,
    header: NCAAB_HEADER,
    copy: NCAAB_COPIES[ncaabRemainingEntries],
    upsertEntry: () =>
      upsertNcaabEntryMutation({
        variables: {
          gameInstanceUid: constants.NCAAB_CHALLENGE_GAME_INSTANCE_UID,
        },
      }),
    upsertEntryResult: upsertNcaabEntryMutationResult,
    seasonType: ncaabGameInstance?.currentPeriod?.segment.season.season,
    seasonYear: ncaabGameInstance?.currentPeriod?.segment.season.year,
    productAbbrev: ncaabGameInstance?.currentPeriod?.segment.season.productAbbrev,
  }

  //getting required information for NCAAW
  const ncaawBracketState = context?.globalBracketState?.womensBracketState
  const { upsertEntryMutation: upsertNcaawEntryMutation, upsertEntryMutationResult: upsertNcaawEntryMutationResult } = useUpsertEntryMutation()
  const ncaawGameInstance = context?.allGameInstances.find((gi) => gi.uid === NCAAW_GAME_INSTANCE_UID)
  const ncaawPoolSettings = ncaawGameInstance?.currentPeriod?.segment?.season?.challengePool?.poolSettings
  const ncaawMaxEntriesPerUser =
    ncaawPoolSettings?.__typename === PoolSettingsTypesEnum.LEGACY_POOL_SETTINGS ? ncaawPoolSettings.maxEntriesPerUser : 0
  const ncaawNumberOfEntries = numberOfEntries?.ncaaw ?? 0
  const ncaawRemainingEntries = ncaawMaxEntriesPerUser - ncaawNumberOfEntries
  const ncaawInfo: ProductInfoType = {
    isLocked: ncaawBracketState?.isLocked ?? false,
    isPreTournament: ncaawBracketState?.isPreTournament ?? true,
    numberOfEntries: ncaawNumberOfEntries,
    maxNumberOfEntries: ncaawMaxEntriesPerUser,
    remainingEntries: ncaawRemainingEntries,
    order: isInNcaawLobby ? 1 : 2,
    gameInstanceUid: constants.NCAAW_CHALLENGE_GAME_INSTANCE_UID,
    lobbyUrl: generatePath(constants.BRACKET_LOBBY_ROUTE, {
      sportType: "college-basketball",
      subsection: "ncaaw-tournament",
      gameType: "bracket",
    }),
    header: NCAAW_HEADER,
    copy: NCAAW_COPIES[ncaawRemainingEntries],
    upsertEntry: () =>
      upsertNcaawEntryMutation({
        variables: {
          gameInstanceUid: constants.NCAAW_CHALLENGE_GAME_INSTANCE_UID,
        },
      }),
    upsertEntryResult: upsertNcaawEntryMutationResult,
    seasonType: ncaawGameInstance?.currentPeriod?.segment.season.season,
    seasonYear: ncaawGameInstance?.currentPeriod?.segment.season.year,
    productAbbrev: ncaawGameInstance?.currentPeriod?.segment.season.productAbbrev,
  }

  //build text mapping using ncaab game instance. This is mainly to get the link to the rules,
  //which will be shared between the mens and womens game
  const gameText = !!ncaabGameInstance
    ? buildMappingFor(
        ncaabGameInstance.uid,
        ncaabGameInstance?.currentPeriod?.segment.season.season ?? ENUM_POST,
        ncaabGameInstance?.currentPeriod?.segment.season.year,
        ncaabGameInstance?.currentPeriod?.segment.season.productAbbrev,
      )
    : emptyObject

  const promoInfo: PromoInfoType = {
    ncaabInfo,
    ncaawInfo,
    isAuthenticated,
    isInNcaawLobby,
    rulesUrl: gameText.rulesUrl ?? "",
    prizeUnloked: ncaabInfo.numberOfEntries > 0 && ncaawInfo.numberOfEntries > 0,
  }

  return promoInfo
}

export default useMainPromoInfo
