import React, { useState, useContext, useEffect } from "react"
import { useHistory } from "react-router"
import PoolDataContext, { PoolDataContextType } from "../../../../Contexts/PoolDataContext"
import SavedPicksConfirmationModal from "./SavedPicksConfirmationModal"
import { IPickUtilsTeam } from "@cbs-sports/sports-shared-client/build/cjs/utils/common-utils-types"
import { emptyObject } from "@cbs-sports/sports-shared-client/build/cjs/utils/constant-utils"
import { NCAAB_BRACKET_LOCKS_AT_TIMESTAMP } from "../../../../../common/constants"
import { buildPickUtils } from "../../../../hooks/usePoolData"
import { NcaaLogoSvg } from "@cbs-sports/sports-shared-client/build/cjs/components/icons"

const SavedBracketModal = () => {
  const poolData: PoolDataContextType = useContext(PoolDataContext)
  const history = useHistory()
  const { centralBracketState, centralTeams, gameInstanceUid, currentSportTypes } = poolData || emptyObject
  const [isBracketComplete, setIsBracketComplete] = useState<boolean>(false)
  const [updatedBracketChampionTeam, setUpdatedBracketChampionTeam] = useState<IPickUtilsTeam | undefined>(undefined)
  const [championIsPlayIn, setChampionIsPlayIn] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [isChallenge, setIsChallenge] = useState<boolean>(false)
  const openConfirmationModal = () => setShowConfirmation(true)
  const closeConfirmationModal = () => {
    setShowConfirmation(false)
    if (history.location.state && history.location.state["saveBracketInfo"]) {
      const state: any = { ...history.location.state }
      delete state.saveBracketInfo
      history.replace({ ...history.location, state })
    }
  }

  useEffect(() => {
    if (history.location.state && history.location.state["saveBracketInfo"] && !history.location.state["upsellDataInfo"]) {
      const { isComplete, championId, isChallengePool } = history.location.state["saveBracketInfo"]
      openConfirmationModal()
      setIsBracketComplete(isComplete || false)
      setIsChallenge(isChallengePool || false)
      if (isComplete) {
        let data: string | undefined
        if (poolData) {
          const pickUtils = buildPickUtils(poolData, null, [])
          data = pickUtils?.bracketMapping?.itemIdPlayinItemNameOverrides[championId || ""]
        }
        if (data) {
          const playInTeam: IPickUtilsTeam = {
            abbrev: data,
            mediumName: data,
            shortName: data,
            colorHexDex: "",
            colorPrimaryHex: "",
            nickName: data,
            location: data,
            conferenceAbbrev: data,
            colorSecondaryHex: "",
            sportType: currentSportTypes?.[0],
            cbsTeamId: 0,
            id: data,
            imageUrl: "",
          }
          setUpdatedBracketChampionTeam(playInTeam)
          setChampionIsPlayIn(true)
        } else {
          const team = centralTeams.find((t) => t.id === championId ?? "")
          setUpdatedBracketChampionTeam(team as IPickUtilsTeam)
        }
      }
    }
  }, [centralTeams, currentSportTypes, history.location.state, poolData])

  return (
    <SavedPicksConfirmationModal
      isBracketComplete={isBracketComplete}
      isOpen={showConfirmation}
      team={updatedBracketChampionTeam}
      close={closeConfirmationModal}
      closeBtnAction={closeConfirmationModal}
      lockTime={centralBracketState?.bracketState?.locksAt || NCAAB_BRACKET_LOCKS_AT_TIMESTAMP}
      gameInstanceUid={gameInstanceUid}
      logo={championIsPlayIn ? <NcaaLogoSvg /> : undefined}
      isChallengeEntry={isChallenge}
    />
  )
}

export default SavedBracketModal
